export const SET_TO_INITIAL='SET_TO_INITIAL'
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const FETCH_USER_DETAIL_REQUEST= 'FETCH_USER_DETAIL_REQUEST'
export const FETCH_USER_DETAIL_SUCCESS= 'FETCH_USER_DETAIL_SUCCESS'
export const FETCH_USER_DETAIL_ERROR = 'FETCH_USER_DETAIL_ERROR'

export const SELECTED_TAB = 'SELECTED_TAB';

export const BRAND_COLOR_UPDATE = 'BRAND_COLOR_UPDATE';
export const START = 'START'
export const STOP = 'STOP'
export const RESET = 'RESET'
export const NEXT_OR_PREV = 'NEXT_OR_PREV'
export const UPDATE_BRAND_BG = 'UPDATE_BRAND_BG';
export const UPDATE_BRAND_LOGO = 'UPDATE_BRAND_LOGO';
export const UPDATE_BRAND_PHOTO = 'UPDATE_BRAND_PHOTO';
export const UPDATE_BRAND_ID = 'UPDATE_BRAND_ID';
export const UPDATE_BRAND_NAME = 'UPDATE_BRAND_NAME';

// export const Set_sidenav_links = "Set_sidenav_links"

export const Set_create_survey = "Set_create_survey";
export const Set_should_create_connection = "Set_should_create_connection";
export const Set_connection_source = "Set_connection_source";
export const SET_GLOBAL_MESSAGE = "SET_GLOBAL_MESSAGE";
export const SET_FILTER_PARAMS = "SET_FILTER_PARAMS";
export const SET_DATE_PARAMS = "SET_DATE_PARAMS";
export const SET_COMMON_TABLE_LOADING = "SET_COMMON_TABLE_LOADING";
export const UPDATE_FIREBASE_CRED = "UPDATE_FIREBASE_CRED";
export const SET_TO_INITIAL_COMMON_STORE="SET_TO_INITIAL_COMMON_STORE";