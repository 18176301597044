import * as actionTypes from '../actions/actionTypes';


const initialState = {
  filters: {
    loading: false,  
  },
  brandColor: '#7EA63F',
  brandBgColor: '#f7f7ff',
  startTour: true,
   // sideNavLinks : [],
  createSurvey: [],
  // globalMessage:{
  //   mess:'',
  //   type:'',
  //   position:""
  // },
  commonTableLoading:false,
 fcm_token:null,
 device_id:null
}

const genReducer = (state = initialState, action) => {
  switch(action.type){
      
    case actionTypes.BRAND_COLOR_UPDATE:
      return {
        ...state,
        brandColor: action.payload
      }

      case actionTypes.UPDATE_FIREBASE_CRED:
        return {
          ...state,
          fcm_token:action.payload.fcm_token,
 device_id:action.payload?.device_id
        }

    case actionTypes.UPDATE_BRAND_BG:
      return{
        ...state,
        brandBgColor:action.payload
      }

    case actionTypes.UPDATE_BRAND_PHOTO:
      return{
        ...state,
        brandPhoto:action.payload
      }

    case actionTypes.UPDATE_BRAND_LOGO:
      return{
        ...state,
        brandLogo:action.payload
      }

    case actionTypes.UPDATE_BRAND_ID:
      return{
        ...state,
        brandId:action.payload
      }

    case actionTypes.UPDATE_BRAND_NAME:
      return{
        ...state,
        brandName:action.payload
      }

    // case actionTypes.Set_sidenav_links:
    //   return{
    //     ...state,
    //     sideNavLinks : action.payload.sort((a,b) => a.sort_order > b.sort_order ? 1 : -1)
    //   }

    case actionTypes.Set_create_survey:
      return{
        ...state,
        createSurvey:action.payload
      }

    // case actionTypes.SET_GLOBAL_MESSAGE:{
    //   return {
    //     ...state,
    //     globalMessage: action.payload
    //   }
    // }
    case actionTypes.SET_COMMON_TABLE_LOADING:{
      return {
        ...state,
        commonTableLoading: action.payload
      }
    }
    
    
    case actionTypes.SET_TO_INITIAL:
      return initialState;

    default:
      return state;

    }
}

export default genReducer;