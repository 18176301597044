import { combineReducers } from "redux";
import auth from "./authReducer";
import gen from "./generalReducer";
import accMang from "./accMangReducer";
import chat from "../apps/Chat/reducer";
import filters from "../commons/components/Filter/reducer";
import reviews from "../apps/Reviews/reducer";
import guestReq from "../apps/GuestRequest/reducer";
import nav from "../commons/components/Navbar/reducer";
import teamCom from "../apps/TeamCommunication/reducer";
import settings from "../commons/components/Settings/reducer";
import commonReducer from "./commonReducer";

export default combineReducers({
  auth: auth,
  nav: nav,
  gen: gen,
  accMang: accMang,
  filter: filters,
  chat: chat,
  review: reviews,
  guestReq: guestReq,
  teamCom: teamCom,
  settings: settings,
  common:commonReducer
});
